import http from '../clients/Axios'

export default class PurchaseReceiveService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          if (el === 'rcv_no') {
            filters_param =
              filters_param + '&' + el + '=' + filters[el].value.trim()
          } else {
            filters_param = filters_param + '&' + el + '=' + filters[el].value
          }
        }
      })
    }

    const res = await http.get(
      `/receive/paginate?page=${pageNumber}&size=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async getPriceChange(
    pageNumber,
    pageSize = 10,
    sortField,
    sortOrder,
    filters
  ) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          if (el === 'rcv_no') {
            filters_param =
              filters_param + '&' + el + '=' + filters[el].value.trim()
          } else {
            filters_param = filters_param + '&' + el + '=' + filters[el].value
          }
        }
      })
    }

    const res = await http.get(
      `/receive/paginate?page=${pageNumber}&size=${pageSize}&sort=${sort}${filters_param}&status=printed`
    )

    return res.data
  }

  async get() {
    const res = await http.get('/receive')
    return res
  }

  async getBy(param) {
    param = param ? param : ''
    const res = await http.get(`/receive${param}`)
    return res
  }

  async getById(id) {
    // param = param ? param : ''
    const res = await http.get(`/receive/${id}`)
    return res
  }

  async add(form) {
    const res = await http.post('/receive', form)
    return res
  }

  async update(id, form) {
    const res = await http.put(`/receive/${id}`, form)
    return res
  }

  async updatePriceChange(id, form) {
    const res = await http.put(`/receive/${id}/price_change`, form)
    return res
  }

  async delete(id) {
    const res = await http.delete(`/receive/${id}`)
    return res
  }

  async print(id) {
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/receive/${id}/print?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async printDraft(id) {
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/receive/${id}/draft?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async syncItem(form) {
    const res = await http.post('/receive/item/sync', form)
    return res
  }
}
