<template>
  <div>
    <DataTable
      v-model:filters="rowFilters"
      v-model:expandedRows="expandedRows"
      :value="items"
      :row-hover="true"
      :loading="loading"
      :paginator="true"
      :rows="10"
      :scrollable="true"
      :lazy="true"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      filterDisplay="row"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      class="p-datatable-sm"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <template v-if="hasHeader" #header>
        <slot name="header"></slot>
      </template>
      <slot name="columns"></slot>
      <template #expansion="details">
        <div>
          <div class="flex mb-3">
            <div class="flex mr-3">
              <div
                style="display: flex; align-items: center"
                class="status-badge bg-pink-100 py-2"
              >
                <Badge severity="danger" class="mr-2"></Badge>
                <!--  eslint-disable-next-line vue/no-parsing-error -->
                {{ 'Hrg. Jual Skrg < Hrg. Jual Preset' }}
              </div>
            </div>
            <div class="mr-3">
              <div
                style="display: flex; align-items: center; height: 100%"
                class="status-badge bg-blue-100"
              >
                <Badge severity="info" class="mr-2"></Badge>
                <!--  eslint-disable-next-line vue/no-parsing-error -->
                {{ `Hrg. Jual Baru < Hrg. Jual Preset` }}
              </div>
            </div>
            <div class="">
              <div
                style="display: flex; align-items: center; height: 100%"
                class="status-badge bg-green-100"
              >
                <Badge severity="success" class="mr-2"></Badge>
                Item Dengan Perubahan Harga
              </div>
            </div>
          </div>
          <DataTable
            :row-class="rowClass"
            :value="details.data.details"
            :scrollable="true"
            editMode="cell"
          >
            <template #empty>
              <div class="text-center">Tidak ada data.</div>
            </template>
            <template #loading>
              <div class="text-center">Memuat data, harap tunggu...</div>
            </template>
            <Column
              field="item_barcode_1"
              class="bg-gray"
              header="Barcode"
              style="min-width: 8rem"
            />
            <Column
              field="item_name"
              header="Deskripsi"
              class="bg-gray"
              style="min-width: 12rem"
            />
            <Column
              field="cost_s"
              header="HARGA NET"
              class="bg-gray"
              style="
                min-width: 12rem;
                max-width: 12rem;
                justify-content: flex-end;
              "
            >
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data.cost_s) }}
              </template>
            </Column>
            <Column
              field="margin"
              header="MARGIN SKRG"
              class="bg-gray"
              style="
                min-width: 12rem;
                max-width: 12rem;
                justify-content: flex-end;
              "
            >
              <template #body="slotProps">
                {{ slotProps.data.margin }}%
              </template>
            </Column>
            <Column
              field="margin_pst"
              header="MARGIN PST"
              class="bg-gray"
              style="
                min-width: 12rem;
                max-width: 12rem;
                justify-content: flex-end;
              "
            >
              <template #body="slotProps">
                {{ slotProps.data.margin_pst }} %
              </template>
            </Column>
            <Column
              field="price_pst"
              header="HRG.JUAL SKRG"
              class="bg-gray"
              style="
                min-width: 12rem;
                max-width: 12rem;
                justify-content: flex-end;
              "
            >
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data.price_pst) }}
              </template>
            </Column>
            <Column
              field="price"
              header="HRG.JUAL PST"
              class="bg-gray"
              style="
                min-width: 12rem;
                max-width: 12rem;
                justify-content: flex-end;
              "
            >
              <template #body="slotProps">
                {{ formatCurrency(slotProps.data.price) }}
              </template>
            </Column>
            <Column
              field="new_price"
              key="new_price"
              header="HRG.JUAL BARU"
              class="bg-gray"
              style="
                min-width: 12rem;
                max-width: 12rem;
                justify-content: flex-end;
              "
            >
              <template #body="{ data }">
                {{ formatCurrency(data.new_price) }}
              </template>
              <!-- <template #editor="{ data }">
                <InputText
                  v-model="data.new_price"
                  class="w-full"
                  input-class="w-full"
                  autofocus
                />
              </template> -->
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { formatCurrency } from '@/helpers/index'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
    filters: {
      type: Object,
      default() {
        return {}
      },
    },
    hasHeader: {
      type: Boolean,
      defaut: false,
    },
  },
  data() {
    return {
      expandedRows: [],
      temp: this.$props.items || [],
      rowFilters: this.filters,
      options: this.gridOptions,
    }
  },
  computed: {
    roles() {
      const user = JSON.parse(localStorage.getItem('user'))
      const roles = user.roles

      if (roles && roles.length > 0) {
        return roles.map((item) => item.name)
      }
      return []
    },
  },

  methods: {
    initFilters() {
      this.rowFilters = this.$props.filters
      this.onFilter()
    },
    formatCurrency(val) {
      return formatCurrency(val, 0, 0)
    },
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.rowFilters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.filters = this.rowFilters
      this.options.page = 1
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    rowClass(data) {
      if (data.mark === 3) {
        return 'bg-green-100'
      }
      if (data.mark === 2) {
        return 'bg-blue-100'
      }
      if (data.mark === 1) {
        return 'bg-pink-100'
      }
      if (data.mark === 0) {
        return 'default-row'
      }
    },
  },
}
</script>
<style scoped>
.default-row .bg-gray {
  background-color: #f8f9fa;
}
</style>
